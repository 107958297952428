import React from "react";
import BottomSheet from "../../components/BottomSheet";
import { useNavigate } from "react-router-dom";

const SplashScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="tpu__splashScreen__wrapper layoutContainer">
      {/* Slider */}
      <div className="tpu__splashScreen__slider">
        <div className="tpu__splashScreen__content">
          <div className="tpu__splashScreen__img">
            <img
              src="https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014398/tp-app-assests/onboard-1_zeprst.png"
              alt="img"
            />
          </div>
          <div className="tpu__splashScreen__title">
            <h2>A Platform For Online Earners</h2>
          </div>
          <div className="tpu__splashScreen__para">
            <p>
              Task Planet Is An Online Money Earning Website For The Individuals
              Looking For Small Tasks And Getting Paid For It
            </p>
          </div>
        </div>
      </div>
      {/* Bottom Sheet */}
      <BottomSheet height="40%">
        <div className="tpu__splashScreen__bottomSheet__content">
          <div className="section__title">
            <h2>Login with Task Planet</h2>
          </div>
          <div className="section__para">
            <p>
              Login to earn points using various amazing and easy tools provided
              in the app and then use earned points for reward!!
            </p>
          </div>
          <div className="google__login__btn">
            <button type="button" className="google__btn">
              <img
                src="https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014540/tp-app-assests/icons/google_pnf6be.png"
                alt="google icon"
              />
              <span>Continue with google</span>
            </button>
            <button
              type="button"
              className="other__login"
              onClick={() => navigate("/authSplashScreen")}
            >
              Other Login Method
            </button>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default SplashScreen;
