import React from "react";
import Helmet from "react-helmet";
import logo from "../assets/images/logo.png";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "react-photo-view/dist/react-photo-view.css";
import { Toaster } from "react-hot-toast";
import Routers from "../routes";
const App = () => {
  return (
    <>
      <Helmet>
        <title>Taskplanet</title>
        <link rel="shortcut icon" href={logo} type="image/x-icon" />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <Toaster position="top-center" reverseOrder={true} />
      <Routers />
    </>
  );
};

export default App;
