import React, { useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const EmailForgotPasswordScreen = () => {
  const [isFocus, setFocus] = useState(false);
  const [data, setData] = useState({
    email: "",
  });
  const handleChange = (e) => [
    setData({
      ...data,
      [e.target.name]: e.target.value,
    }),
  ];
  const navigate = useNavigate();
  return (
    <div className="emailForgotPassword__wrapper layoutContainer">
      <div className="emailForgotPassword__header">
        <div className="tpu__header__widgets">
          <span className="leftArrowIcon">
            <FaArrowLeftLong onClick={() => navigate(-1)} />
          </span>
          <div className="tpu__header__name">
            <h2>Forgot Password</h2>
          </div>
          <span className="questionIcon">
            <GoQuestion />
          </span>
        </div>
      </div>
      <div className="emailForgotPassword__content__area">
        <div className="emailForgotPassword__heading">
          <h2>Forgot Password</h2>
          <p>
            Enter the email associated with your account and we'll send an email
            with instructions to reset your password.
          </p>
        </div>
        <div className="emailForgotPassword__image">
          <img
            src="https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014399/tp-app-assests/signup_urkdhi.jpg"
            alt="img"
          />
        </div>
        <div className="emailForgotPassword__inputField">
          <label htmlFor="email">Email Address</label>
          <div className="emailField">
            <span>
              <MdEmail  style={{ color: isFocus ? "#0b59f9" : "#000" }} />
            </span>
            <input
              type="text"
              name="email"
              value={data.email}
              className="emailInput"
              onChange={handleChange}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              placeholder="Enter your registered email"
              id="email"
            />
          </div>
          <div className="submitButton">
            <button
              type="submit"
              className="loginButton"
              disabled={!data.email}
            >
              <span>Send OTP</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailForgotPasswordScreen;
