import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const MobileForgotPasswordScreen = () => {
  const [isFocus, setFocus] = useState(false);
  const [data, setData] = useState({
    mobile: "",
  });
  const handleChange = (e) => [
    setData({
      ...data,
      [e.target.name]: e.target.value,
    }),
  ];
  const navigate = useNavigate();
  return (
    <div className="mobileForgotPassword__wrapper layoutContainer">
      <div className="mobileForgotPassword__header">
        <div className="tpu__header__widgets">
          <span className="leftArrowIcon">
            <FaArrowLeftLong onClick={() => navigate(-1)} />
          </span>
          <div className="tpu__header__name">
            <h2>Forgot Mobile Password</h2>
          </div>
          <span className="questionIcon">
            <GoQuestion />
          </span>
        </div>
      </div>
      <div className="mobileForgotPassword__content__area">
        <div className="mobileForgotPassword__heading">
          <h2>Forgot Password</h2>
          <p>
            Enter the mobile number associated with your account and we'll send
            an otp to reset your password
          </p>
        </div>
        <div className="mobileForgotPassword__image">
          <img
            src="https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014399/tp-app-assests/signup_urkdhi.jpg"
            alt="img"
          />
        </div>
        <div className="mobileForgotPassword__inputField">
          <label htmlFor="mobile">Mobile Number</label>
          <div className="mobileNumberField">
            <span>
              <FaPhoneAlt style={{ color: isFocus ? "#0b59f9" : "#000" }} />
            </span>
            <input
              type="number"
              name="mobile"
              value={data.mobile}
              className="mobileInput"
              onChange={handleChange}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
          </div>
          <div className="submitButton">
            <button
              type="submit"
              className="loginButton"
              disabled={!data.mobile}
            >
              <span>Send Reset OTP</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileForgotPasswordScreen;
