import React from "react";
import { Route, Routes } from "react-router-dom";
import SplashScreen from "../screens/SplashScreen";
import AuthSplashScreen from "../screens/SplashScreen/AuthSplashScreen";
import MobileLoginScreen from "../screens/AuthPage/MobileLogin";
import EmailLoginScreen from "../screens/AuthPage/EmailLogin";
import MobileForgotPasswordScreen from "../screens/AuthPage/MobileForgotPassword";
import EmailForgotPasswordScreen from "../screens/AuthPage/EmailForgotPassword";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route path="/*" index element={<SplashScreen />} />
        <Route path="/authSplashScreen" element={<AuthSplashScreen />} />
        <Route path="/loginWithMobile" element={<MobileLoginScreen />} />
        <Route path="/loginWithEmail" element={<EmailLoginScreen />} />
        <Route
          path="/mobileForgotPassword"
          element={<MobileForgotPasswordScreen />}
        />
        <Route
          path="/emailForgotPassword"
          element={<EmailForgotPasswordScreen />}
        />
      </Routes>
    </>
  );
};

export default Routers;
