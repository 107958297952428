import React, { useState } from "react";
import BottomSheet from "../../components/BottomSheet";
import { Link, useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";

const EmailLoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("data", data);
  };
  return (
    <div className="tpu__emailLoginScreen__wrapper layoutContainer">
      {/* Header */}
      <TopHeader title="Login/Register" para="Ready to play and earn?" />
      {/* Bottom Sheet */}
      <BottomSheet height="85%">
        <div className="tpu__emailLoginScreen__bottomSheet__content">
          <form onSubmit={handleSubmit}>
            <div className="emailLoginForm">
              <div className="emailLoginField">
                <input
                  type="text"
                  name="username"
                  value={data.username}
                  className="loginInput"
                  onChange={handleChange}
                  placeholder="Username or Email"
                />
              </div>
              {data?.username && (
                <>
                  <div className="emailLoginField">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={data.password}
                      className="loginInput"
                      onChange={handleChange}
                      placeholder="Password"
                    />
                    <span className="eyeIcon">
                      {showPassword ? (
                        <IoEye onClick={() => setShowPassword(!showPassword)} />
                      ) : (
                        <IoMdEyeOff
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </span>
                  </div>
                  <Link to="/emailForgotPassword" className="forgotPassword">
                    Forgot Password?
                  </Link>
                </>
              )}
            </div>

            <div className="submitButton">
              <button
                type="submit"
                className="loginButton"
                disabled={!data.username || !data.password}
              >
                <span>Send OTP</span>
              </button>
            </div>
          </form>
        </div>
      </BottomSheet>
    </div>
  );
};

export default EmailLoginScreen;
