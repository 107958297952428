import React from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const TopHeader = ({ title, para }) => {
  const navigate = useNavigate();
  return (
    <div className="tpu__header__section">
      <div className="tpu__header__widgets">
        <span className="leftArrowIcon">
          <FaArrowLeftLong onClick={() => navigate(-1)} />
        </span>
        <div className="tpu__header__name">
          {title && <h2>{title}</h2>}
          {para && <p>{para}</p>}
        </div>
        <span className="questionIcon">
          <GoQuestion />
        </span>
      </div>
    </div>
  );
};

export default TopHeader;
