import React, { useState } from "react";
import BottomSheet from "../../components/BottomSheet";
import { Link, useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import { FaPhoneAlt } from "react-icons/fa";

const MobileLoginScreen = () => {
  const [isFocus, setFocus] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    mobile: "",
  });
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("data", data);
  };
  return (
    <div className="tpu__mobileLoginScreen__wrapper layoutContainer">
      {/* Header */}
      <TopHeader title="Login/Register" para="Ready to play and earn?" />
      {/* Bottom Sheet */}
      <BottomSheet height="85%">
        <div className="tpu__mobileLoginScreen__bottomSheet__content">
          <form onSubmit={handleSubmit}>
            <div className="mobileLoginForm">
              <div className="mobileLoginForm__heading">
                <p>Enter your mobile number</p>
                <p>We will send you OTP to verify</p>
              </div>
              <div className="mobileNumberField">
                <span>
                  <FaPhoneAlt style={{ color: isFocus ? "#0b59f9" : "#000" }} />
                </span>
                <input
                  type="number"
                  name="mobile"
                  value={data.mobile}
                  className="mobileInput"
                  onChange={handleChange}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
              </div>
              <Link to="/mobileForgotPassword" className="forgotPassword">
                Forgot Password?
              </Link>
            </div>

            <div className="submitButton">
              <button type="submit" className="loginButton">
                <span>Send OTP</span>
              </button>
            </div>
          </form>
        </div>
      </BottomSheet>
    </div>
  );
};

export default MobileLoginScreen;
